/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { FirebaseNamespace } from '@firebase/app-types';
import { _FirebaseApp, _FirebaseNamespace } from '@firebase/app-types/private';
import { createSubscribe, deepExtend, ErrorFactory } from '@firebase/util';
import { FirebaseAppImpl } from './firebaseApp';
import { createFirebaseNamespaceCore } from './firebaseNamespaceCore';

/**
 * Return a firebase namespace object.
 *
 * In production, this will be called exactly once and the result
 * assigned to the 'firebase' global. It may be called multiple times
 * in unit tests.
 */
export function createFirebaseNamespace(): FirebaseNamespace {
 const namespace = createFirebaseNamespaceCore(FirebaseAppImpl);
 (namespace as _FirebaseNamespace).INTERNAL = {
 ...(namespace as _FirebaseNamespace).INTERNAL,
 createFirebaseNamespace,
 extendNamespace,
 createSubscribe,
 ErrorFactory,
 deepExtend
 };

 /**
 * Patch the top-level firebase namespace with additional properties.
 *
 * firebase.INTERNAL.extendNamespace()
 */
 function extendNamespace(props: { [prop: string]: unknown }): void {
 deepExtend(namespace, props);
 }

 return namespace;
}

export const firebase = createFirebaseNamespace();
